.icn-bg {
    font-size: 17pt
}

.icn-sm {
    font-size: 14pt
}

@keyframes pointer {
    100%,0%{
        background-color: rgb(255,127,0);
    }
    50%{
        background-color: rgb(2, 44, 230);
    }
}

@keyframes svg-pointer {
    100%,0%{
        fill: rgb(255,127,0);
    }
    50%{
        fill: rgb(2, 44, 230);
    }
}

@keyframes pointer-border {
    100%,0%{
        border-color: rgb(255,127,0);
    }
    50%{
        border-color: rgb(2, 44, 230);
    }
}

/* DOT FLASHING ANIMATION */

.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: blue;
    color: #4015ffbc;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}
  
.dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}
  
.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: blue;
    color: #4015ffbc;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}
  
.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: blue;
    color: #4015ffbc;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}
  
@keyframes dotFlashing {
    0% {
      background-color: #4015ffbc;
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
}
  
._google-map-tooltip {
    /* position: relative; */
    display: flex;
    justify-content: center;
    width: 200px;
    padding: 16px 10px;
    height: auto;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 4px 20px rgba(34, 34, 34, 0.1);
    /* margin-top: -50px; */
}
  
._google-map-tooltip > .g-tooltip {
    /* position: absolute; */
    /* bottom: -6px; */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    height: inherit;

    width: 200px;

}